import React, { Suspense, lazy } from 'react';
import Navbar from "./components/navbar.component";
import './App.css';
import { Provider, useSelector } from 'react-redux';
import store, { StoreSelector } from './store/store';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import { AuthGuard } from "./guards/authGuard";
import commonCss, { mobileView } from "./commonCss/commonCss";
import { StyleSheet, css } from "aphrodite";
import { AdminGuard } from "./guards/adminGuard";
import LoadingSpinner from './components/LoadingSpinner';

const HomePage = lazy(() => import('./scenes/HomePage.scenes'));
const LoginPage = lazy(() => import('./scenes/LoginPage.scenes'));
const ContactUsPage = lazy(() => import('./scenes/ContactUsPage.scenes'));
const QRScanPage = lazy(() => import('./scenes/QRScanPage.scenes'));
const ServicesPage = lazy(() => import('./scenes/SanginiServicesPage.scenes'));
const BuyJewelleriesPage = lazy(() => import('./scenes/BuyJewelleriesPage.scenes'));
const UserProfilePage = lazy(() => import('./scenes/UserProfilePage.scenes'));
const SignUpPage = lazy(() => import('./scenes/SignUpPage.scenes'));
const PrivacyPolicy = lazy(() => import('./scenes/PrivacyPolicy.scenes'));
const ForgotPasswordPage = lazy(() => import('./scenes/forgotPassword.scenes'));
const TermsAndConditions = lazy(() => import('./scenes/TermsAndConditon.scenes'));
const AdminPage = lazy(() => import('./scenes/AdminPage.scenes'));
const ProductDetail = lazy(() => import('./components/admin/products/productDetails.component'));

function App() {
  const user = useSelector((state: StoreSelector) => state.user);
  return (
      <BrowserRouter>
        <Navbar defaultAction="Buy Jewelleries">
          <Navbar.Actions label='Home'/>
          <Navbar.Actions label='Buy Jewelleries'/>
          <Navbar.Actions label='Services'/>
          <Navbar.Actions label='Contact Us'/>
          <Navbar.Actions label='QR Scan'/>
          {user.isAdmin && <Navbar.Actions label='Admin'/>}

        </Navbar>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path='/BuyJewelleries' element={<BuyJewelleriesPage />} />
            <Route path='/Home' element={<HomePage />} />
            <Route path='/login' element={<LoginPage shouldLogout={true} />} />
            <Route path='/contactUs' element={<ContactUsPage />} />
            <Route path='/QRScan' element={<QRScanPage />} />
            <Route path='/Services' element={<ServicesPage />} />
            <Route path='/profile' element={<AuthGuard><UserProfilePage/></AuthGuard>} />
            <Route path='/signup' element={<SignUpPage />} />
            <Route path='/forgotPassword' element={<ForgotPasswordPage />} />
            <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
            <Route path="/termsandcondition" element={<TermsAndConditions/>}/>
            <Route path='/Admin' element={<AdminGuard><AdminPage/></AdminGuard>} />
            <Route path='/customer' element={<AdminGuard><AdminPage/></AdminGuard>} />
            <Route path='/newCustomer' element={<AdminGuard><AdminPage/></AdminGuard>} />
            <Route path='/rate' element={<AdminGuard><AdminPage/></AdminGuard>} />
            <Route path='/billing' element={<AdminGuard><AdminPage/></AdminGuard>} />
            <Route path='/editcustomer' element={<AdminGuard><AdminPage/></AdminGuard>} />
            <Route path='/addProduct' element={<AdminGuard><AdminPage/></AdminGuard>} />
            <Route path='/products' element={<AdminGuard><AdminPage/></AdminGuard>} />
            <Route path='/editProduct/:id' element={<AdminGuard><AdminPage/></AdminGuard>} />
            <Route path='/product/:id' element={<AdminGuard><ProductDetail/></AdminGuard>} />
            <Route path="/" element={<HomePage />} />
          </Routes>
        </Suspense>
        <footer>
          <div className={css(commonCss.flexRow, commonCss.alignItemsCenter, commonCss.justifySpaceBetween, styles.footer)}>
            <div>Copyright © 2022 Sangini Jewellers - All Rights Reserved.</div>
            <div className={css(styles.textRight)}>
              Sole Proprietorship
            </div>
          </div>
          <div className={css(commonCss.flexRow, commonCss.alignItemsCenter, commonCss.justifySpaceBetween, styles.footercontent)}>
            <Link to="/privacyPolicy" className={css(styles.privacyLink)}>Privacy Policy</Link>
            <Link to="/termsandcondition" className={css(styles.privacyLink)}>Terms And Condition</Link>
          </div>
        </footer>
      </BrowserRouter>
  );
}

const styles = StyleSheet.create({
  footer: {
    margin: "50px 10% 0 10%",
    padding: "25px 0 20px 0",
    [mobileView]: {
      margin: "10% 10px",
      fontSize: "0.8rem"
    },
    borderTop: "1px solid #ccc",
    color: "#666",
  },
  textRight: {
    textAlign: "right",
  },
  footercontent : {
    margin: "0px 10% 0 10%",
    padding: "25px 0 10px 0",
    [mobileView]: {
      margin: "10% 10px",
      fontSize: "0.8rem"
    },
    textDecoration: "none",
  },
  privacyLink: {
    textDecoration: "none", 
    color: "inherit", 
  },
});

export default App;
