import { createSlice } from "@reduxjs/toolkit";

export interface DiamondRates {
    si_GH: {'+10to11':number,'+11-12':number,'+12-14':number,'+2to6.5':number,'+6.5to8':number,'+8to9':number,'+9to10':number,'-2':number,'1/6(0.14-0.17)':number,'1/5(0.18-0.22)':number,'1/4(0.23-0.29)':number,'1/3(0.30-0.37)':number},
    si_IJ: {'+10to11':number,'+11-12':number,'+12-14':number,'+2to6.5':number,'+6.5to8':number,'+8to9':number,'+9to10':number,'-2':number,'1/6(0.14-0.17)':number,'1/5(0.18-0.22)':number,'1/4(0.23-0.29)':number,'1/3(0.30-0.37)':number},
    si_JK: {'+10to11':number,'+11-12':number,'+12-14':number,'+2to6.5':number,'+6.5to8':number,'+8to9':number,'+9to10':number,'-2':number,'1/6(0.14-0.17)':number,'1/5(0.18-0.22)':number,'1/4(0.23-0.29)':number,'1/3(0.30-0.37)':number},
    si_i_FG: {'+10to11':number,'+11-12':number,'+12-14':number,'+2to6.5':number,'+6.5to8':number,'+8to9':number,'+9to10':number,'-2':number,'1/6(0.14-0.17)':number,'1/5(0.18-0.22)':number,'1/4(0.23-0.29)':number,'1/3(0.30-0.37)':number},
    vs_si_FG: {'+10to11':number,'+11-12':number,'+12-14':number,'+2to6.5':number,'+6.5to8':number,'+8to9':number,'+9to10':number,'-2':number,'1/6(0.14-0.17)':number,'1/5(0.18-0.22)':number,'1/4(0.23-0.29)':number,'1/3(0.30-0.37)':number},
    vs_si_GH: {'+10to11':number,'+11-12':number,'+12-14':number,'+2to6.5':number,'+6.5to8':number,'+8to9':number,'+9to10':number,'-2':number,'1/6(0.14-0.17)':number,'1/5(0.18-0.22)':number,'1/4(0.23-0.29)':number,'1/3(0.30-0.37)':number},
    vs_si_HI: {'+10to11':number,'+11-12':number,'+12-14':number,'+2to6.5':number,'+6.5to8':number,'+8to9':number,'+9to10':number,'-2':number,'1/6(0.14-0.17)':number,'1/5(0.18-0.22)':number,'1/4(0.23-0.29)':number,'1/3(0.30-0.37)':number},
    vs_si_IJ: {'+10to11':number,'+11-12':number,'+12-14':number,'+2to6.5':number,'+6.5to8':number,'+8to9':number,'+9to10':number,'-2':number,'1/6(0.14-0.17)':number,'1/5(0.18-0.22)':number,'1/4(0.23-0.29)':number,'1/3(0.30-0.37)':number},
    vvs_EF: {'+10to11':number,'+11-12':number,'+12-14':number,'+2to6.5':number,'+6.5to8':number,'+8to9':number,'+9to10':number,'-2':number,'1/6(0.14-0.17)':number,'1/5(0.18-0.22)':number,'1/4(0.23-0.29)':number,'1/3(0.30-0.37)':number},
    vvs_vs_FG: {'+10to11':number,'+11-12':number,'+12-14':number,'+2to6.5':number,'+6.5to8':number,'+8to9':number,'+9to10':number,'-2':number,'1/6(0.14-0.17)':number,'1/5(0.18-0.22)':number,'1/4(0.23-0.29)':number,'1/3(0.30-0.37)':number},
    vvs_vs_GH: {'+10to11':number,'+11-12':number,'+12-14':number,'+2to6.5':number,'+6.5to8':number,'+8to9':number,'+9to10':number,'-2':number,'1/6(0.14-0.17)':number,'1/5(0.18-0.22)':number,'1/4(0.23-0.29)':number,'1/3(0.30-0.37)':number},
    vvs_vs_HI: {'+10to11':number,'+11-12':number,'+12-14':number,'+2to6.5':number,'+6.5to8':number,'+8to9':number,'+9to10':number,'-2':number,'1/6(0.14-0.17)':number,'1/5(0.18-0.22)':number,'1/4(0.23-0.29)':number,'1/3(0.30-0.37)':number},
}

const initialState: DiamondRates = {
    si_GH: {'+10to11':0,'+11-12':0,'+12-14':0,'+2to6.5':0,'+6.5to8':0,'+8to9':0,'+9to10':0,'-2':0,'1/6(0.14-0.17)':0,'1/5(0.18-0.22)':0,'1/4(0.23-0.29)':0,'1/3(0.30-0.37)':0},
    si_IJ: {'+10to11':0,'+11-12':0,'+12-14':0,'+2to6.5':0,'+6.5to8':0,'+8to9':0,'+9to10':0,'-2':0,'1/6(0.14-0.17)':0,'1/5(0.18-0.22)':0,'1/4(0.23-0.29)':0,'1/3(0.30-0.37)':0},
    si_JK: {'+10to11':0,'+11-12':0,'+12-14':0,'+2to6.5':0,'+6.5to8':0,'+8to9':0,'+9to10':0,'-2':0,'1/6(0.14-0.17)':0,'1/5(0.18-0.22)':0,'1/4(0.23-0.29)':0,'1/3(0.30-0.37)':0},
    si_i_FG: {'+10to11':0,'+11-12':0,'+12-14':0,'+2to6.5':0,'+6.5to8':0,'+8to9':0,'+9to10':0,'-2':0,'1/6(0.14-0.17)':0,'1/5(0.18-0.22)':0,'1/4(0.23-0.29)':0,'1/3(0.30-0.37)':0},
    vs_si_FG: {'+10to11':0,'+11-12':0,'+12-14':0,'+2to6.5':0,'+6.5to8':0,'+8to9':0,'+9to10':0,'-2':0,'1/6(0.14-0.17)':0,'1/5(0.18-0.22)':0,'1/4(0.23-0.29)':0,'1/3(0.30-0.37)':0},
    vs_si_GH: {'+10to11':0,'+11-12':0,'+12-14':0,'+2to6.5':0,'+6.5to8':0,'+8to9':0,'+9to10':0,'-2':0,'1/6(0.14-0.17)':0,'1/5(0.18-0.22)':0,'1/4(0.23-0.29)':0,'1/3(0.30-0.37)':0},
    vs_si_HI: {'+10to11':0,'+11-12':0,'+12-14':0,'+2to6.5':0,'+6.5to8':0,'+8to9':0,'+9to10':0,'-2':0,'1/6(0.14-0.17)':0,'1/5(0.18-0.22)':0,'1/4(0.23-0.29)':0,'1/3(0.30-0.37)':0},
    vs_si_IJ: {'+10to11':0,'+11-12':0,'+12-14':0,'+2to6.5':0,'+6.5to8':0,'+8to9':0,'+9to10':0,'-2':0,'1/6(0.14-0.17)':0,'1/5(0.18-0.22)':0,'1/4(0.23-0.29)':0,'1/3(0.30-0.37)':0},
    vvs_EF: {'+10to11':0,'+11-12':0,'+12-14':0,'+2to6.5':0,'+6.5to8':0,'+8to9':0,'+9to10':0,'-2':0,'1/6(0.14-0.17)':0,'1/5(0.18-0.22)':0,'1/4(0.23-0.29)':0,'1/3(0.30-0.37)':0},
    vvs_vs_FG: {'+10to11':0,'+11-12':0,'+12-14':0,'+2to6.5':0,'+6.5to8':0,'+8to9':0,'+9to10':0,'-2':0,'1/6(0.14-0.17)':0,'1/5(0.18-0.22)':0,'1/4(0.23-0.29)':0,'1/3(0.30-0.37)':0},
    vvs_vs_GH: {'+10to11':0,'+11-12':0,'+12-14':0,'+2to6.5':0,'+6.5to8':0,'+8to9':0,'+9to10':0,'-2':0,'1/6(0.14-0.17)':0,'1/5(0.18-0.22)':0,'1/4(0.23-0.29)':0,'1/3(0.30-0.37)':0},
    vvs_vs_HI: {'+10to11':0,'+11-12':0,'+12-14':0,'+2to6.5':0,'+6.5to8':0,'+8to9':0,'+9to10':0,'-2':0,'1/6(0.14-0.17)':0,'1/5(0.18-0.22)':0,'1/4(0.23-0.29)':0,'1/3(0.30-0.37)':0}
}

const diamondSlice = createSlice({
    name: "diamondRates",
    initialState,
    reducers:{
        setDiamondRateState(state, action){
            state = action.payload;
            return state;
        },
        setDiamondRate(state, action){
            state[action.type as keyof typeof state] = action.payload;
        }
    }
})

export const {setDiamondRate, setDiamondRateState} = diamondSlice.actions;
export default diamondSlice.reducer;