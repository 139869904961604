import { DiamondRateDTO } from "../models/diamondRate";
import { Query } from "../library/query";
import { DiamondRates } from "../store/diamondRateSlice";
// import DiamondRates from "../components/admin/ratesubcomponents/diamondrates.component";

export type DiamondTypes = {
    si_GH: string,
    si_IJ: string,
    si_JK: string,
    si_i_FG: string,
    vs_si_FG: string,
    vs_si_GH: string,
    vs_si_HI: string,
    vs_si_IJ: string,
    vvs_EF: string,
    vvs_vs_FG: string,
    vvs_vs_GH: string,
    vvs_vs_HI: string,
}

export class DiamondRateRepository{
    private static diamondRateCollection = Query.getCollection('diamondrates');

    // static async todaysRate(): Promise<DiamondRateDTO>{
    //     const rateData:any = await this.diamondRateCollection.findOne(orderBy('timestamp', 'desc'), limit(1));
    //     return DiamondRateDTO.fromJSON(rateData)
    // }

    static async addRate(data: Record<string, any>,customID: string | undefined){
        await this.diamondRateCollection.addNewDoc(data,customID)
    }

    static async getRates(){
        const ratesData = await this.diamondRateCollection.getAllDocs()
        return ratesData;
    }

    static async rateByType(type: keyof DiamondRates){
        const rateData = await this.diamondRateCollection.findById(type)
        return DiamondRateDTO.fromJSON(rateData as any).rate
    }
}