import { Query } from "../library/query";
import { UserDTO } from "../models/users";



export class UserRepository {
    private static userCollection = Query.getCollection('users');

    static async getAllUserDetails(): Promise<Array<UserDTO|undefined>> {
        const users = await this.userCollection.getAllDocs();
        const usersDTOArr: Array<UserDTO | undefined> = []
        users.forEach(user => {
            usersDTOArr.push(UserDTO.fromJSON(user))
        })
        return usersDTOArr
    }

    static async getUserDetails(userId: string): Promise<UserDTO|undefined> {
        return UserDTO.fromJSON(await this.userCollection.findById(userId));
    }

    static async save(user: object) {

    }
}