import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import goldRateReducer, {GoldRateStateData} from "./goldRateSlice";
import userReducer, {UserDetails} from "./userSlice";
import productsReducer ,{ProductStateData} from "./productsSlice";
import productReducer from "./productSlice";
import productTagsReducer, { TagState } from "./productTagSlice";
import loadingReducer from "./loadingSlice";
import errorReducer from "./errorSlice";
import billReducer from "./billSlice";
import diamondRateReducer, { DiamondRates } from "./diamondRateSlice";
import fileReducer from "./filesSlice";
//import { useDispatch } from "react-redux";
import { User } from "firebase/auth";
import customersReducer, { CustomerStateData } from "./customersSlice";
import { Bill } from "./billSlice";
import totalProductsCountReducer from "./productsCountSlice";

export type StoreSelector = {
    auth: User,
    goldRate: GoldRateStateData,
    user: UserDetails,
    products:ProductStateData[],
    product: ProductStateData,
    customers: CustomerStateData[],
    tags: TagState[],
    bill: Bill,
    diamondRates: DiamondRates,
    totalProductsCount: number,
}

const store = configureStore({
    reducer: {
        auth: authReducer,
        goldRate: goldRateReducer,
        user: userReducer,
        products: productsReducer,
        customers: customersReducer,
        product: productReducer,
        tags: productTagsReducer,
        loading: loadingReducer,
        error: errorReducer,
        bill: billReducer,
        diamondRates: diamondRateReducer,
        files: fileReducer,
        totalProductsCount: totalProductsCountReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// Hook to use `AppDispatch` type
export default store;