// components/LoadingSpinner.tsx
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingSpinner = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <Spin indicator={<LoadingOutlined size={24} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>} />
  </div>
);

export default LoadingSpinner;