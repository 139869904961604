// fileSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface FilesState {
    files: File[];
    currentFile: File | null;
}

const initialState: FilesState = {
    files: [],
    currentFile: null,
};

const fileSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        setFiles(state, action: PayloadAction<File[]>) {
            state.files = action.payload;
        },
        setCurrentFile(state, action: PayloadAction<File | null>) {
            state.currentFile = action.payload;
        },
        resetFiles(state) {
            state.files = [];
            state.currentFile = null;
        },
    },
});

export const { setFiles, setCurrentFile, resetFiles } = fileSlice.actions;
export default fileSlice.reducer;