import { createSlice } from "@reduxjs/toolkit";



const initialState:  number = 0; 

const productSlice = createSlice({
    name: 'productsCount',
    initialState,
    reducers: {
      setTotalProductsCount(state: number, action: { payload: number }) {
        state = action.payload;
        return state;
      },

      resetTotalProductsCount() {
        return initialState
      },
    },     
});

export const {setTotalProductsCount, resetTotalProductsCount} = productSlice.actions;
export default productSlice.reducer;