import { DiamondRates } from "../store/diamondRateSlice"

export interface DiamondRateConstructorObjectInterface {
    
    '+2to6.5': number,
    '+6.5to8': number,
    '+8to9': number,
    '+9to10': number,
    '+10to11': number,
    '-2': number,
    '+11-12': number,
    '+12-14': number,
    '1/6(0.14-0.17)': number,
    '1/5(0.18-0.22)': number,
    '1/4(0.23-0.29)': number,
    '1/3(0.30-0.37)': number 
}


export class DiamondRateDTO{
    private constructor (private diamondRate: DiamondRateConstructorObjectInterface) {}
    
    get rate(){
        return {
            '+2to6.5': this.diamondRate["+2to6.5"],
            '+6.5to8': this.diamondRate["+6.5to8"],
            '+8to9': this.diamondRate["+8to9"],
            '+9to10': this.diamondRate["+9to10"],
            '+10to11': this.diamondRate["+10to11"],
            '-2': this.diamondRate["-2"],
            '+11-12': this.diamondRate["+11-12"],
            '+12-14': this.diamondRate["+12-14"],
            '1/6(0.14-0.17)': this.diamondRate["1/6(0.14-0.17)"],
            '1/5(0.18-0.22)': this.diamondRate["1/5(0.18-0.22)"],
            '1/4(0.23-0.29)': this.diamondRate["1/4(0.23-0.29)"],
            '1/3(0.30-0.37)': this.diamondRate["1/3(0.30-0.37)"] 
        }
    }

    static fromJSONByDiamondType(diamondRates: DiamondRates, diamondClarity: string, diamondColor: string){
        const diamondClarity_Color = `${diamondClarity}_${diamondColor}` as keyof DiamondRates;
        if (diamondClarity_Color in diamondRates){
            const data = diamondRates[diamondClarity_Color as keyof DiamondRates];
            return new DiamondRateDTO({
                    '+2to6.5': data['+2to6.5'],
                    '+6.5to8': data['+6.5to8'],
                    '+8to9': data['+8to9'],
                    '+9to10': data['+9to10'],
                    '+10to11': data['+10to11'],
                    '-2': data['-2'],
                    '+11-12': data['+11-12'],
                    '+12-14': data['+12-14'],
                    '1/6(0.14-0.17)': data['1/6(0.14-0.17)'],
                    '1/5(0.18-0.22)': data['1/5(0.18-0.22)'],
                    '1/4(0.23-0.29)': data['1/4(0.23-0.29)'],
                    '1/3(0.30-0.37)': data['1/3(0.30-0.37)']
            })
        }
        else {
            return new DiamondRateDTO({
                '+2to6.5': 0,
                '+6.5to8': 0,
                '+8to9': 0,
                '+9to10': 0,
                '+10to11': 0,
                '-2': 0,
                '+11-12': 0,
                '+12-14': 0,
                '1/6(0.14-0.17)': 0,
                '1/5(0.18-0.22)': 0,
                '1/4(0.23-0.29)': 0,
                '1/3(0.30-0.37)': 0
            })
        }
    }

    static fromJSON(diamondRate: DiamondRates['si_GH']){
        return new DiamondRateDTO({
            '+2to6.5': diamondRate['+2to6.5'],
            '+6.5to8': diamondRate['+6.5to8'],
            '+8to9': diamondRate['+8to9'],
            '+9to10': diamondRate['+9to10'],
            '+10to11': diamondRate['+10to11'],
            '-2': diamondRate['-2'],
            '+11-12': diamondRate['+11-12'],
            '+12-14': diamondRate['+12-14'],
            '1/6(0.14-0.17)': diamondRate['1/6(0.14-0.17)'],
            '1/5(0.18-0.22)': diamondRate['1/5(0.18-0.22)'],
            '1/4(0.23-0.29)': diamondRate['1/4(0.23-0.29)'],
            '1/3(0.30-0.37)': diamondRate['1/3(0.30-0.37)']
        })
    }

    toJSON(){
        return {
            ...this.rate
        }
    }
}