import { limit, orderBy } from "firebase/firestore";
import { Query } from "../library/query";
import { GoldRateDTO } from "../models/goldRate";




export class RateRepository {
    private static rateCollection = Query.getCollection('rates');

    static async todaysRate(): Promise<GoldRateDTO> {
        const rateData:any  = await this.rateCollection.findOne(orderBy('timestamp', 'desc'), limit(1));
        return GoldRateDTO.fromJSON(rateData);
    }

    static todaysRateOnChange(callback: (data: GoldRateDTO) => void): () => void {
        return this.rateCollection.findFirstWithSnapshot((snapshot) => {
            const rateData = snapshot;
            callback(GoldRateDTO.fromJSON(rateData));
        }, orderBy('timestamp', 'desc'), limit(1));
    }
    static async updateRate(id: string, data:any) {
        await this.rateCollection.updateDocById(id,{
            ...data,
        });
    }

    static async AddRate(data:any) {
        await this.rateCollection.addNewDoc({
            ...data,
        });
    }
}

export class RateDiffRepository {
    private static rateDiffCollection = Query.getCollection('ratesdiff');

    static async UpdateDiff(diff:number){
        return await this.rateDiffCollection.updateDocById('6vQJVeAW3KaSo5vjl0xh',{difference: diff});
    }
}