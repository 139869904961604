import { createSlice } from "@reduxjs/toolkit";
import { ProductStateData } from "./productsSlice";


const initialState: ProductStateData = {
  productTagNumber: "",
  productName: "",
  description: "",
  makingCharges: 15,
  timeTakenForMaking: 15,
  category: "",
  subCategory: "",
  images: [],
  goldWeight: 0,
  goldKarat: "18K",
  goldColor: "Yellow",
  goldWastage: 5,
  stoneType: "",
  stoneWeight: 0,
  stonePieces: 0,
  stoneCost: 0,
  diamondColor: "",
  diamondClarity: '',
  diamondWeight: 0,
  diamondSize: "",
  diamondPieces: 0,
  availableNow: false,
  availableOnOrder: false,
  availableOnEmi: false,
  hsnValue: "7113",
}

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
      setProduct(state: ProductStateData, action: { payload: Object }) {
        state = {...state, ...action.payload};
        return state;
      },

      resetProduct() {
        return {...initialState};
      },

      partialResetProduct(state: ProductStateData) {
        state = {
          ...initialState,
          category: state.category,
          subCategory: state.subCategory,
          goldKarat: state.goldKarat,
          productName: state.productName,
          productTagNumber: state.productTagNumber,};
        return state;
      }
    },
});

export const {setProduct, resetProduct, partialResetProduct} = productSlice.actions;
export default productSlice.reducer;