import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerStateData } from "./customersSlice";
import { ProductStateData } from "./productsSlice";

export type Bill =  {
    customerDetails: CustomerStateData
    products: ProductStateData[],
    totalPriceBreakdown: {
        totalMakingCharges: number,
        totalProductValue: number,
        igst : number,
        cgst : number,
        sgst : number,
        discount:number,
        exchangeValue: number,
        netPayableAmount: number,
    },
    paymentsInfo: {
        paidAmount: number,
        paymentMode: string,
    }[],
    returnedAmount: number,
    remainingAmount: number,
    billNumber: string,
    timestamp: number,
}

type PriceBreakdownKeys = keyof Bill['totalPriceBreakdown']

const initialState: Bill = {
    customerDetails: {
    } as CustomerStateData,
    products: [],
    totalPriceBreakdown: {
        totalMakingCharges: 0,
        totalProductValue: 0,
        igst: 0,
        cgst: 0,
        sgst: 0,
        discount: 0,
        exchangeValue: 0,
        netPayableAmount: 0,
    },
    paymentsInfo: [{
        paidAmount: 0,
        paymentMode: "Cash"
    }],
    remainingAmount: 0,
    returnedAmount: 0,
    billNumber: "",
    timestamp: (new Date()).getTime(),
}

const billSlice = createSlice({
    name: 'bill',
    initialState : initialState,
    reducers: {
        setCustomer(state, action: PayloadAction<CustomerStateData>){
            state.customerDetails = action.payload;
            return state;
        },
        unsetCustomer(state){
            state.customerDetails = initialState.customerDetails;
            return state;
        },
        addProductToBill(state, action: PayloadAction<ProductStateData>) {
            const index = state.products.findIndex( product => product.productTagNumber === action.payload.productTagNumber);
            if(index === -1){
                state.products.push(action.payload);
                return state;
            }
            return state;
            
        },
        removeProductFromBill(state, action:{payload: typeof state.products[0]}){
            const index = state.products.findIndex( product => product.productTagNumber === action.payload.productTagNumber);
            if( index>=0){
                state.products.splice(index, 1);
                return state;
            }
            return state;
            
        },
        configPriceBreakdown(state, action:{payload:{ key: PriceBreakdownKeys, value: number}}){
            const {key, value} = action.payload;
            state.totalPriceBreakdown[key] = value;
            return state;
        },
        addPaymentInfo(state, action: {payload: {info: {paidAmount?: number, paymentMode?: string}, index: number} | {info: {paidAmount: number, paymentMode: string}, index: undefined}}){
            if(action.payload.index !== undefined){
                if(action.payload.info.paidAmount!==undefined){
                    state.paymentsInfo[action.payload.index].paidAmount = action.payload.info.paidAmount;
                }if(action.payload.info.paymentMode){
                    state.paymentsInfo[action.payload.index].paymentMode = action.payload.info.paymentMode;
                }
            }else{
                state.paymentsInfo.push(action.payload.info);
            }
            return state;
        },
        setBillNo(state, action){
            state.billNumber = action.payload;
            return state;
        },
        setRemainingAmount(state, action: PayloadAction<Bill['remainingAmount']>) {
            state.remainingAmount = action.payload;
            return state;
        },

        setReturnedAmount(state, action: PayloadAction<Bill['returnedAmount']>) {
            state.returnedAmount = action.payload;
            return state;
        },
        setTimeStamp(state, action){
            state.timestamp = action.payload;
            return state;
        },
    },
});

export const { setCustomer, unsetCustomer, addProductToBill, removeProductFromBill, configPriceBreakdown, addPaymentInfo, setBillNo, setTimeStamp, setRemainingAmount, setReturnedAmount} = billSlice.actions;
export default billSlice.reducer;