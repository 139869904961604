import { useEffect } from "react";
import { useDispatch } from "react-redux"
import { DiamondRateRepository } from "../repositories/diamondRateRepository";
import { DiamondRates, setDiamondRateState } from "../store/diamondRateSlice";

export const useDiamondRateHook = () => {
    const dispatch = useDispatch();

    useEffect( () => {
            DiamondRateRepository.getRates().then(diamondRates => {
                const ratesData = {} as any;
                diamondRates.forEach((data)=>{
                    const {id,...rates} = data;
                    ratesData[data.id] = {...rates}
                });
                dispatch(setDiamondRateState(ratesData as DiamondRates))
            })
        }
    ,[])
}