
import { CollectionReference, DocumentData, QueryConstraint, collection, doc, getDoc, getDocs, query, setDoc, addDoc, deleteDoc, onSnapshot, limit, orderBy, getCountFromServer, startAt, startAfter, QuerySnapshot } from "firebase/firestore"
import { firestore } from "../config/firestore"
import { Unsubscribe } from "firebase/auth";


export const Collections = {
    rates: 'rates',
    bills: 'bills',
    customers: 'customers',
    customercopy: 'customercopy',
    users: 'users',
    ratescopy:'ratescopy',
    products:'products',
    products_digital: 'products_digital',
    productTags: 'productTags',
 //   productscopy: 'productscopy',
    diamondrates: 'diamondrates',
    ratesdiff: 'ratesdiff',
    tags: 'tags',
    billNumber: 'billNumber',
}


export class Query {
    getNewDocRef() {
      throw new Error("Method not implemented.");
    }
    private collectionRef!: CollectionReference<DocumentData>;
    private constructor (collectionName: keyof typeof Collections) {
        this.collectionRef = collection(firestore, collectionName);
    }
    public static getCollection(collectionName: keyof typeof Collections) {
        return new Query(collectionName);
    }
    async getTotalDocsCount () {
        return (await getCountFromServer(this.collectionRef)).data().count;
    }
    async getAllDocs() {
        const docs = await getDocs(this.collectionRef);
        const docsData: DocumentData[] = []
        docs.forEach(doc => docsData.push({...doc.data(), id: doc.id}));
        return docsData
    }

    async getNDocs(n: number, after?: any) {
        let q = query(this.collectionRef, orderBy('updatedAt', 'desc'), limit(n));
        
        if (after) {
            const afterDoc = await getDoc(doc(this.collectionRef, after.id))
            q = query(this.collectionRef, orderBy('updatedAt', 'desc'), startAfter(afterDoc), limit(n));
        }
        const docs = await getDocs(q);
        const docsData: DocumentData[] = []
        docs.forEach(doc => docsData.push({...doc.data(), id: doc.id}));
        return docsData
    }

    async findOne(...queryConstraint: QueryConstraint[]) {
        const q = await query(this.collectionRef, ...queryConstraint);
        const docs = await getDocs(q);
        return {...(docs.docs[0].data()), id: docs.docs[0].id}

    }
    async findById(id: string) {
        const q = doc(this.collectionRef, id);
        const docData = await getDoc(q);
        return {...docData.data(), id: docData.id}
    }
    async updateDocById(id: string, data:Record<string, any>) {
        const q = doc(this.collectionRef, id);
        await setDoc( q, data, {merge:true});
        return id;
    }
    async addNewDoc(data: Record<string, any>, customID?:string) {
        if (customID){
            const docRef = doc(this.collectionRef, customID);
            await setDoc(docRef, data);
            return {id:customID};
        }
        return await addDoc(this.collectionRef, data);
    }

    async getDocById(id: string): Promise<DocumentData | null> { // deprecated
        const docRef = doc(this.collectionRef, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return { id: docSnap.id, ...docSnap.data() };
        } else {
            console.log("No such document!");
            return null;
        }
    }

    async searchDoc(wheres: Array<QueryConstraint> ): Promise<DocumentData[]>{
        const q = await query(this.collectionRef, ...wheres)
        const snapshot = await getDocs(q);
        const docsData: DocumentData[]=[]
        if (!!snapshot) {
            snapshot.forEach(doc => {
                docsData.push({id: doc.id, ...doc.data()});
                // console.log(doc.id, '=>', doc.data());
            });
            return docsData;
            
        }  
        console.log('No matching documents.');
        return [];
    }

    async removeDocById(id:string){
        const q = doc(this.collectionRef, id);
        await deleteDoc(q);
        return id;
    }


    // create new function of all the above with onSnapshot
    findFirstWithSnapshot(callback: (doc: DocumentData) => void, ...queryConstraint: QueryConstraint[]): Unsubscribe {
        return onSnapshot(query(this.collectionRef, ...queryConstraint), (snapshot) => {
            if (!snapshot.empty) {
                const docData = snapshot.docs[0].data();
                callback({id: snapshot.docs[0].id, ...docData});
            }
        });
    }
    findAllWithSnapshot(callback: (docs: DocumentData[]) => void, ...queryConstraint: QueryConstraint[]): Unsubscribe {
        return onSnapshot(query(this.collectionRef, ...queryConstraint), (snapshot) => {
            const docsData: DocumentData[] = []
            snapshot.forEach(doc => docsData.push({id: doc.id, ...doc.data()}));
            callback(docsData);
        });
    }
    findByIdWithSnapshot(callback: (doc: DocumentData) => void, id: string): Unsubscribe {
        return onSnapshot(doc(this.collectionRef, id), (doc) => {
            if (doc.exists()) {
                callback({id: doc.id, ...doc.data()});
            }
        });
    }
}
